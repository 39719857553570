/* eslint-disable */
import { closeApi, closeApiUpload } from "./resolver";

const queries = {
  get_user_kyc_details: {
    query: "query get_user_kyc_details ($data: GetUserKycDetailsType!){ get_user_kyc_details(data: $data) { hasError title message userErrors data }}",
    response: (response) => response.data.get_user_kyc_details
  },
  get_email_verification_otp: {
    query: "query get_email_verification_otp ($data: GetEmailVerificationOtpInputType!){ get_email_verification_otp(data: $data) { hasError title message data { emailOtp isEmailVerified } }}",
    response: (response) => response.data.get_email_verification_otp
  },
  update_user_kyc_overall_status: {
    query: "mutation update_user_kyc_overall_status ($data: UpdateKycOverallStatusType!){ update_user_kyc_overall_status(data: $data) { hasError title message userErrors data }}",
    response: (response) => response.data.update_user_kyc_overall_status
  },

  update_individual_docs_kyc_status: {
    query: "mutation update_individual_docs_kyc_status ($data: UpdateIndividualDocsKycStatusInputType!) { update_individual_docs_kyc_status (data:$data ) { hasError title message userErrors data }}",
    response: (response) => response.data.update_individual_docs_kyc_status
  },
  request_for_extra_info: {
    query: "mutation request_for_extra_info( $data:  RequestForExtraInfoInputType!) { request_for_extra_info( data:$data) { hasError title message userErrors data }}",
    response: (response) => response.data.request_for_extra_info
  },
  update_contact_info_kyc_status: {
    query: "mutation update_contact_info_kyc_status ( $data:UpdateContactInfoKycStatusInputType!) { update_contact_info_kyc_status(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_contact_info_kyc_status
  },
  update_address_kyc_status: {
    query: "mutation update_address_kyc_status ( $data:UpdateAddressKycStatusInputType!) {update_address_kyc_status(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_address_kyc_status
  },
  exchange_product: {
    query: "mutation exchange_product($data: ExchangeProductInputType!) { exchange_product(data: $data) { hasError title message userErrors data }}",
    response: (response) => response.data.exchange_product
  },
  get_kyc_list: {
    query: `query get_kyc_list ($data: GetKycListInputType!) { get_kyc_list(data: $data) { hasError title message userErrors data {
      dataList {
        kycInfo { id status documentsEvaluations contactsEvaluations evaluationTags createdAt submittedAt }
        user { id email mobNo whatsappNo firstName middleName lastName }
      }
      remainingCount
     }}}`,
    response: (response) => response.data.get_kyc_list
  },
  get_kyc_evaluation_tags: {
    query: "query get_kyc_evaluation_tags { get_kyc_evaluation_tags { hasError title message userErrors data { tag description } }}",
    response: (response) => response.data.get_kyc_evaluation_tags
  },
  set_kyc_evaluation_tags: {
    query: "mutation set_kyc_evaluation_tags( $data:SetKycEvaluationTagsInputType!) { set_kyc_evaluation_tags(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.set_kyc_evaluation_tags
  },
  change_document_type: {
    query: "mutation change_document_type ($data:ChangeDocumentTypeInputType!) { change_document_type(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.change_document_type
  },
  get_user_and_delivery_address_details: {
    query: "query get_user_and_delivery_address_details($data: GetUserAndDeliveryAddressDetailsInputType!) { get_user_and_delivery_address_details(data: $data) { hasError title message userErrors data { address { id type houseNo floorNo apartmentOrStreet landmark city pin } userInfo { id firstName middleName lastName whatsappNo mobileNo alternateMobileNo email extraInfo kycStatus } } } }",
    response: (response) => response.data.get_user_and_delivery_address_details
  },
  get_picked_up_details: {
    query: "query get_picked_up_details($data: GetPickedUpDetailsInputType!) { get_picked_up_details(data: $data) { hasError title message userErrors data { pickupId address { id type houseNo floorNo apartmentOrStreet landmark city pin } products { id serialNo model brand rentRefunded pendingRent securityDeposit meta } customer { id firstName middleName lastName whatsappNo mobileNo alternateMobileNo email extraInfo } pickupCharge totalAmountReturned totalAmountCollected transaction { amount, txDate txMode txType utr gateway paymentUrl status } } } }",
    response: (response) => response.data.get_picked_up_details
  },
  get_product_details_by_serial_no: {
    query: "query get_product_details_by_serial_no($data: GetProductDetailsBySerialNoInputType!) { get_product_details_by_serial_no(data: $data) { hasError title message userErrors data { productId serialNo status productType modelName brandName extraInfo } } }",
    response: (response) => response.data.get_product_details_by_serial_no
  },
  get_delivery_list: {
    query: `query get_delivery_list($data: GetDeliveryListInputType!) { get_delivery_list(data: $data) {
      hasError
      title
      message
      userErrors
      data {
        dataList {
          delivery {
            id
            status
            deliveryAt
            activity
            noOfAssets
          }
          user {
            id
            firstName
            middleName
            lastName
            whatsappNo
            mobileNo
            alternateMobileNo
            email
            extraInfo
          }
        }
        remainingCount
      }
    }
  }`,
    response: (response) => response.data.get_delivery_list
  },
  get_picked_up_list: {
    query: `query get_picked_up_list($data: GetPickedUpListInputType!) { get_picked_up_list(data: $data) {
      hasError
      title
      message
      userErrors
  data {
      dataList {
        pickup {
          id
          pickupAt
          status
          noOfAssets
        }
        transactionInfo {
          id
          amount
          txDate
          txMode
          txType
          utr
          gateway
          paymentUrl
          status
        }
        user {
          id
          firstName
          middleName
          lastName
          fullName
          whatsappNo
          mobileNo
          alternateMobileNo
          email
          extraInfo
          customerAddress {
            addressId
            addressType
            houseNumber
            floorNumber
            apartmentOrStreet
            landmark
            city
            status
            pin
          }
        }
      }
      remainingCount
    }
    }
  }`,
    response: (response) => response.data.get_picked_up_list
  },
  get_delivery_info: {
    query: `query get_delivery_info($data: GetDeliveryInfoInputType!) { get_delivery_info(data: $data) {
      hasError
      title
      message
      userErrors
      data {
        deliveryId
        address {
          id
          type
          houseNo
          floorNo
          apartmentOrStreet
          landmark
          city
          pin
        }
        deliveryPerson {
          id
          firstName
          middleName
          lastName
          emailId
          mobileNo
        }
        products {
          id
          serialNo
          model
          brand
          securityDeposit
          deliveryInfo {
            accessories {
              ramSize
              romSize
            }
          }
          rentWithTax
          rentAmount
          status
          rentedDays
          replaces {
            id
            serialNo
            model
            brand
            securityDeposit
            rentWithTax
            rentAmount
            rentedDays
            replaces {
              id
              serialNo
              model
              brand
              securityDeposit
              rentWithTax
              rentAmount
              rentedDays
            }
          }
        }
        customer {
          firstName
          middleName
          lastName
          whatsappNo
          mobileNo
          alternateMobileNo
          email
          extraInfo
        }
        transaction { amount, txDate txMode txType utr gateway paymentUrl status }
        deliveryCharge
      }
    } }`,
    response: (response) => response.data.get_delivery_info
  },
  add_delivery_details: {
    query: "mutation add_delivery_details($data: AddDeliveryDetailsInputType!) { add_delivery_details(data: $data) { hasError title message userErrors data } }",
    response: (response) => response.data.add_delivery_details
  },
  save_pickup_details: {
    query: "mutation save_pickup_details($data: SavePickupDetailsInputType!) { save_pickup_details(data: $data) { hasError title message userErrors data } }",
    response: (response) => response.data.save_pickup_details
  },
  save_delivery_address_map_url: {
    query: "mutation save_delivery_address_map_url ($data:SaveDeliveryAddressMapUrlInputType!) { save_delivery_address_map_url(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.save_delivery_address_map_url
  },
  get_variant_list: {
    query: `query get_variant_list ($data:GetVariantListInputType!){ get_variant_list(data: $data) {
       hasError
       title
       message
       userErrors
       data {
        dataList{ id code codeDescription updatedAt }
        remainingCount
       }
    }}`,
    response: (response) => response.data.get_variant_list
  },
  create_variant: {
    query: "mutation create_variant ($data:CreateProductVariantInputType!) {create_variant(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_variant
  },
  update_variant: {
    query: "mutation update_variant($data:UpdateVariantInputType!) {update_variant(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_variant
  },
  get_priceTag_list: {
    query: `query get_priceTag_list ($data:GetPriceTagInputType!){ get_priceTag_list(data: $data) {
       hasError
       title
       message
       userErrors
       data {
        dataList{ id tag description createdAt}
        remainingCount
       }
    }}`,
    response: (response) => response.data.get_priceTag_list
  },
  create_price_tag: {
    query: "mutation create_price_tag ($data:CreatePriceTagInputType!) {create_price_tag(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_price_tag
  },
  update_price_Tag: {
    query: "mutation update_price_Tag($data: UpdatePriceTagInputType!) {update_price_Tag(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_price_Tag
  },
  get_customer_active_assets_and_info: {
    query: `query get_customer_active_assets_and_info($data: GetCustomerActiveAssetsAndInfoInputType!) {
        get_customer_active_assets_and_info(data: $data) {
          hasError
          title
          message
          userErrors
          data {
            address { id type houseNo floorNo apartmentOrStreet landmark city pin }
            customer { id firstName middleName lastName whatsappNo mobileNo alternateMobileNo email extraInfo }
            products { id deliveryId serialNo model brand securityDeposit rentWithTax rentAmount rentedDays deliveryInfo {
                accessories { ramSize romSize }
                itemsSupplied { laptop battery charger mouse }
              }
            }
            deliveryCharge
          }
        }
      }`,
    response: (response) => response.data.get_customer_active_assets_and_info
  },
  update_kyc_submission_status: {
    query: "mutation update_kyc_submission_status($data: UpdateKycSubmissionStatusInputType!) {update_kyc_submission_status(data:$data) { hasError title message userErrors data }}",
    response: (response) => response.data.update_kyc_submission_status
  },
  get_product_list: {
    query: "query get_product_list ($data : GetProductListInputType!) { get_product_list(data : $data) { hasError title message userErrors data { dataList { productId serialNo status modelName brandName locationInfo { locationId locationName } internalSerialNo description updatedAt productType readinessStatus modelId brandId} remainingCount } }}",
    response: (response) => response.data.get_product_list
  },
  add_product: {
    query: "mutation add_product ($data: AddProductInputType!) {add_product(data:$data) { hasError title message userErrors data }}",
    response: (response) => response.data.add_product
  },
  update_product: {
    query: "mutation update_product ($data: UpdateProductInputType! ) {update_product(data:$data) { hasError title message userErrors data }}",
    response: (response) => response.data.update_product
  },
  mark_product_as_obsolete: {
    query: "mutation mark_product_as_obsolete($data: MarkProductAsObsoleteInputType!){mark_product_as_obsolete(data:$data) { hasError title message userErrors data }}",
    response: (response) => response.data.mark_product_as_obsolete
  },
  get_product_model_by_type_and_brand: {
    query: "query get_product_model_by_type_and_brand($data: GetProductModelByTypeAndBrandInputType!) {get_product_model_by_type_and_brand(data: $data){ hasError title message userErrors data { dataList { brandId brandName modelId modelName productType attribute1 attribute2 attribute3 taxPercentage updatedAt } remainingCount} }}",
    response: (response) => response.data.get_product_model_by_type_and_brand
  },
  create_product_model_details: {
    query: "mutation create_product_model_details ($data:CreateProductModelInputType!) {create_product_model_details(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_product_model_details
  },
  create_invoice: {
    query: "mutation create_invoice ($data:CreateInvoiceInputType!) {create_invoice(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_invoice
  },
  update_invoice: {
    query: "mutation update_invoice ($data:UpdateInvoiceInputType!) {update_invoice(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_invoice
  },
  get_product_model_list: {
    query: `query get_product_model_list ($data : GetProductModelListInputType!){ get_product_model_list(data: $data) {
       hasError
       title
       message
       userErrors
       data {
        dataList{
          brandId
          brandName
          modelId
          modelName
          productType
          attribute1
          attribute2
          attribute3
          taxPercentage
          updatedAt
        }
        remainingCount
       }
    }}`,
    response: (response) => response.data.get_product_model_list
  },
  update_product_model_details: {
    query: "mutation update_product_model_details($data:UpdateProductModelInputType!) {update_product_model_details(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_product_model_details
  },
  get_brands_list: {
    query: `query get_brands_list ($data:GetBrandsListInputType!){
      get_brands_list(data:$data) {
      hasError
      title
      message
      userErrors
      data {
       dataList { brandId brandName}
       remainingCount
      }
   }}`,
    response: (response) => response.data.get_brands_list
  },
  create_model_price: {
    query: "mutation create_model_price ($data:ModelPriceInputType!) {create_model_price(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_model_price
  },
  get_model_price_list: {
    query: `query get_model_price_list ($data:GetModelPriceListInputType!){
      get_model_price_list(data:$data) {
      hasError
      title
      message
      userErrors
      data {
       dataList {
        id
        price
        status
        variantId
        variantCode
        priceTagId
        priceTag
        description
        createdAt
       }
       remainingCount
      }
   }}`,
    response: (response) => response.data.get_model_price_list
  },
  get_service_price_list: {
    query: `query get_service_price_list ($data:GetServicePriceListInputType!){
      get_service_price_list(data:$data) {
      hasError
      title
      message
      userErrors
      data {
       dataList {
        pricingId
        serviceId
        price
        status
        variantId
        variantCode
        priceTagId
        priceTag
        description
        createdAt
       }
       remainingCount
      }
   }}`,
    response: (response) => response.data.get_service_price_list
  },
  get_invoice_list: {
    query: `query get_invoice_list ($data:GetInvoiceListInputType!){
      get_invoice_list(data:$data) {
      hasError
      title
      message
      userErrors
      data {
        dataList {
          invoice {
            invoiceId
            invoiceNo
            subscriptionsNo
            invoiceDate
            invoiceDueDate
            invoiceStatus
            invoiceTotalAmount
            createdAt
          }
          user {
            id
            fullName
            whatsappNo
            mobileNo
            alternateMobileNo
            email
            extraInfo
          }
        }
        remainingCount
      }
   }}`,
    response: (response) => response.data.get_invoice_list
  },
  update_model_price: {
    query: "mutation update_model_price($data:UpdateModelPriceInputType!) {update_model_price(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_model_price
  },
  get_users_list: {
    query: "query get_users_list($data: GetUsersListInputType!) {get_users_list(data:$data) {hasError title message userErrors data { dataList { userId email firstName middleName lastName mobileNo whatsAppNo mobileNoLinkedToAadhaar alternativeNo1 alternativeNo2 alternativeNo3 fatherNo motherNo sisterNo brotherNo guardianNo spouseNo accommodation ccEmails gstIn notes businessCategory businessName totalActiveLaptops totalInvoicesCount pendingInvoiceCount creditNoteReceivedTotalAmount pendingInvoiceTotalAmount closingBalance  updatedAt locationId } remainingCount } }}",
    response: (response) => response.data.get_users_list
  },
  create_user: {
    query: "mutation create_user ($data:CreateUserInputType!) {create_user(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_user
  },
  update_user: {
    query: "mutation update_user($data:UpdateUserInputType!) {update_user(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_user
  },
  get_users_address_list: {
    query: "query get_users_address_list($data: GetUsersAddressListInputType!) {get_users_address_list(data:$data) {hasError title message userErrors data { addressList { addressId userId addressType houseNo floorNo apartmentOrStreet landmark city pin status updatedAt } } }}",
    response: (response) => response.data.get_users_address_list
  },
  get_invoice_info: {
    query: "query get_invoice_info($data: GetInvoiceInfoType!) {get_invoice_info(data:$data) { hasError title message userErrors data { invoiceInfo { invoiceId invoiceNo invoiceDate invoiceDueDate invoiceTotalAmount invoiceStatus notes} customerInfo { id firstName middleName lastName whatsappNo mobileNo alternateMobileNo email extraInfo customerAddress { addressId addressType houseNumber floorNumber apartmentOrStreet landmark city status pin } } transactionInfo { transactionAmount utr transactionMode transactionDate } products { invoiceItemId modelId modelName description pricingId variantId quantity unitPriceWithTax serialNos taxPercentage lineTotalAmount } services { invoiceItemId serviceName serviceId description pricingId variantId category quantity unitPriceWithTax taxPercentage lineTotalAmount } } } }",
    response: (response) => response.data.get_invoice_info
  },
  get_invoice_overall_stats: {
    query: "query get_invoice_overall_stats($data: GetInvoiceOverallStatsInputType!) {get_invoice_overall_stats(data:$data) { hasError title message userErrors data { paidAmount totalAmount } } }",
    response: (response) => response.data.get_invoice_overall_stats
  },
  create_user_address: {
    query: "mutation create_user_address ( $data:CreateUserAddressInputType!) {create_user_address(data:$data){ hasError title message userErrors data }}",
    response: (response) => response.data.create_user_address
  },
  update_user_address: {
    query: "mutation update_user_address($data:UpdateUserAddressInputType!) {update_user_address(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_user_address
  },
  upload_user_documents: {
    query: "mutation upload_user_documents($data: UpdateUserDocumentInputType!) {upload_user_documents(data:$data){ hasError title message userErrors data }}",
    response: (response) => response.data.upload_user_documents
  },
  enable_user_kyc: {
    query: "mutation enable_user_kyc($data: EnableUserKycInputType!) {enable_user_kyc(data:$data){ hasError title message userErrors data }}",
    response: (response) => response.data.enable_user_kyc
  },
  get_kyc_event_logs: {
    query: "query get_kyc_event_logs($data: GetKycEventsLogInputType!) { get_kyc_event_logs(data:$data) {hasError title message userErrors data { logs { eventId userId username adminId adminName event description meta updatedAt } }} }",
    response: (response) => response.data.get_kyc_event_logs
  },
  get_service_list: {
    query: "query get_service_list($data: GetServicesListInputType!) {get_service_list(data:$data) {hasError title message userErrors data { dataList { serviceId name category taxPercentage showInInvoice updatedAt } remainingCount } }}",
    response: (response) => response.data.get_service_list
  },
  create_service: {
    query: "mutation create_service($data: CreateServicesInputType!) {create_service(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_service
  },
  update_service: {
    query: "mutation update_service($data: UpdateServiceInputType!) {update_service(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_service
  },
  remove_kyc_extra_info: {
    query: "mutation remove_kyc_extra_info($data: RemoveKycExtraInfoInputType!) {remove_kyc_extra_info(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.remove_kyc_extra_info
  },
  get_delivery_list_by_product_serial_no: {
    query: "query get_delivery_list_by_product_serial_no( $data: GetDeliveryListByProductSerialNoInputType! ) {get_delivery_list_by_product_serial_no(data: $data){ hasError title message userErrors data { dataList { delivery { id deliveryAt activity noOfAssets } user { id firstName middleName lastName whatsappNo mobileNo alternateMobileNo email extraInfo } } remainingCount } }}",
    response: (response) => response.data.get_delivery_list_by_product_serial_no
  },
  get_locations: {
    query: "query  get_locations { get_locations { hasError title message userErrors data { locations { locationId locationName } } } }",
    response: (response) => response.data.get_locations
  },
  get_admin_users_list: {
    query: "query get_admin_users_list {get_admin_users_list { hasError title message userErrors data { dataList { id emailId firstName middleName lastName mobileNo } } }}",
    response: (response) => response.data.get_admin_users_list
  },
  update_product_in_out_status: {
    query: "mutation update_product_in_out_status($data: UpdateProductInOutStatusInputType!) {update_product_in_out_status(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_product_in_out_status
  },
  save_product_readiness_details: {
    query: "mutation save_product_readiness_details($data: SaveProductReadinessInputType!) {save_product_readiness_details(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.save_product_readiness_details
  },
  get_product_readiness_details: {
    query: "query get_product_readiness_details ($data:  GetProductReadinessDetailsInputType!) {get_product_readiness_details(data: $data) {hasError title message userErrors data { productIssueLogId productId readinessStatus actionTaken { ram1{ cleaned replaced} ram2 { cleaned replaced} ssd{ cleaned replaced} battery{ cleaned replaced} display{ cleaned replaced} touchPad { cleaned replaced} keyboard{ cleaned replaced} fan { cleaned replaced} camera { cleaned replaced} speaker { cleaned replaced} mic { cleaned replaced} bezel { cleaned replaced} hinge { cleaned replaced} timeTakenInMins } actionToBeTaken { ram1 { toBeCleaned toBeReplaced toBeChecked} ram2 { toBeCleaned toBeReplaced toBeChecked} ssd { toBeCleaned toBeReplaced toBeChecked} battery { toBeCleaned toBeReplaced toBeChecked} display { toBeCleaned toBeReplaced toBeChecked} touchPad { toBeCleaned toBeReplaced toBeChecked} keyboard { toBeCleaned toBeReplaced toBeChecked} fan{ toBeCleaned toBeReplaced toBeChecked} camera { toBeCleaned toBeReplaced toBeChecked} speaker { toBeCleaned toBeReplaced toBeChecked} mic { toBeCleaned toBeReplaced toBeChecked} bezel { toBeCleaned toBeReplaced toBeChecked} hinge{ toBeCleaned toBeReplaced toBeChecked}  } lastUpdatedAt }}}",
    response: (response) => response.data.get_product_readiness_details
  },
  get_product_event_logs: {
    query: "query get_product_event_logs ($data : GetProductEventLogsInputType!) { get_product_event_logs(data : $data) { hasError title message userErrors data { logs {eventId productId event   firstPartyName  counterPartyName description meta updatedAt} remainingCount } }}",
    response: (response) => response.data.get_product_event_logs
  },
  get_deliveries_pickups_per_day: {
    query: "query get_deliveries_pickups_per_day ($data : GetDeliveriesPickupsPerDay!) { get_deliveries_pickups_per_day(data : $data) { hasError title message userErrors data { name data { count date } } }}",
    response: (response) => response.data.get_deliveries_pickups_per_day
  },
  get_exchanges_per_day: {
    query: "query get_exchanges_per_day ($data : GetExchangesPerDayInputType!) { get_exchanges_per_day(data : $data) { hasError title message userErrors data { name data { count date } } }}",
    response: (response) => response.data.get_exchanges_per_day
  },
  get_laptop_count_by_status_per_day: {
    query: "query get_laptop_count_by_status_per_day ($data : GetLaptopCountByStatusPerDayInputType!) { get_laptop_count_by_status_per_day(data : $data) { hasError title message userErrors data { name data { count date } } }}",
    response: (response) => response.data.get_laptop_count_by_status_per_day
  },
  create_task_schedule: {
    query: "mutation create_task_schedule($data: TaskSchedulesInputType!) {create_task_schedule(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_task_schedule
  },
  get_task_schedules: {
    query: "query get_task_schedules($data : GetTaskSchedulesInputType!) { get_task_schedules(data: $data) { hasError title message userErrors data { dataList { taskScheduledId taskId scheduledTime assignedBy assignee type status priority notes customerName customerWhatsappNo customerMobileNo mapLink customerAddress {houseNo floorNo apartmentOrStreet landmark city pin} lastUpdatedAt createdAt assigneeId taskLocationId } remainingCount } }}",
    response: (response) => response.data.get_task_schedules
  },
  update_task_schedule: {
    query: "mutation update_task_schedule($data: UpdateTaskScheduleInputType!) {update_task_schedule(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_task_schedule
  },
  save_fcm_token: {
    query: "mutation save_fcm_token($data: SaveFcmTokenInputType!) {save_fcm_token(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.save_fcm_token
  },
  get_delivery_exchange_pickup_earnings: {
    query: "query get_delivery_exchange_pickup_earnings($data: GetDeliveriesExchangesPickupEarningsInputType!) {get_delivery_exchange_pickup_earnings(data: $data) {hasError title message userErrors data { nameOfInternalUser deliveryEarnings exchangeEarnings pickupEarnings } }}",
    response: (response) => response.data.get_delivery_exchange_pickup_earnings
  },
  upload_delivery_images: {
    query: "mutation  upload_delivery_images($data: UploadDeliveryImagesInputType!) {upload_delivery_images(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.upload_delivery_images
  },
  generate_delivery_payment_link: {
    query: "mutation  generate_delivery_payment_link($data: GenerateDeliveryPaymentLinkInputType!) {generate_delivery_payment_link(data: $data) {hasError title message userErrors data { deliveryId paymentUrls { transactionId totalAmount paymentUrl paymentUrlCreatedAt status } } }}",
    response: (response) => response.data.generate_delivery_payment_link
  },
  generate_delivery_payment_link: {
    query: "mutation  generate_delivery_payment_link($data: GenerateDeliveryPaymentLinkInputType!) {generate_delivery_payment_link(data: $data) {hasError title message userErrors data { deliveryId paymentUrls { transactionId totalAmount paymentUrl paymentUrlCreatedAt status } } }}",
    response: (response) => response.data.generate_delivery_payment_link
  },
  get_subscription_list : {
    query: "query get_subscription_list($data: GetSubscriptionListInputType!) {get_subscription_list(data:$data) {hasError title message userErrors data { dataList { subscription { billingCycleInDays lastInvoiceDate nextInvoiceDate subscriptionId subscriptionNo subscriptionStartDate status createdAt totalNoOfInvoices totalNoOfInvoicesPaid totalNoOfInvoicesPending totalNoOfInvoicesCanceled } user { id firstName middleName lastName fullName whatsappNo mobileNo alternateMobileNo email extraInfo customerAddress { addressId addressType houseNumber floorNumber apartmentOrStreet landmark city status pin } } } remainingCount }} }",
    response: (response) => response.data.get_subscription_list
  },
   get_subscription_info: {
    query: "query get_subscription_info($data: GetSubscriptionInfoType!) {get_subscription_info(data:$data) {hasError title message userErrors data { invoiceInfo { invoiceInfo { invoiceId invoiceNo invoiceStatus invoiceDate invoiceDueDate invoiceTotalAmount notes } customerInfo { id firstName middleName lastName fullName whatsappNo mobileNo alternateMobileNo email extraInfo customerAddress { addressId addressType houseNumber floorNumber apartmentOrStreet landmark city status pin } } products { invoiceItemId modelId modelName brandId brandName description pricingId variantId quantity unitPriceWithTax serialNos taxPercentage lineTotalAmount } services { invoiceItemId serviceName serviceId description pricingId variantId category quantity unitPriceWithTax taxPercentage lineTotalAmount } } subscriptionInfo { id customerId subscriptionNo billingCycleInDays subscriptionStartDate lastInvoiceDate nextInvoiceDate status createdBy createdAt } } }}",
    response: (response) => response.data.get_subscription_info
  },
  get_invoices_by_subscription: {
    query: "query get_invoices_by_subscription($data: GetInvoicesBySubscriptionInputType!) {get_invoices_by_subscription(data:$data) {hasError title message userErrors data { dataList { invoiceId invoiceNo invoiceDate invoiceStatus invoiceTotalAmount invoiceDueDate createdAt} remainingCount }}}",
    response: (response) => response.data.get_invoices_by_subscription
  },
  cancel_subscription: {
    query: "mutation cancel_subscription($data: CancelSubscriptionType!) {cancel_subscription(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.cancel_subscription
  },
  create_delivery: {
    query: "mutation create_delivery($data: CreateDeliveryInputType!) {create_delivery(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_delivery
  },
  send_delivery_confirmation_otp: {
    query: "mutation send_delivery_confirmation_otp($data: SendDeliveryConfirmationOtpInputType!) {send_delivery_confirmation_otp(data: $data) {hasError title message userErrors data { isDeliveryCompleted otpExpiresAt  } }}",
    response: (response) => response.data.send_delivery_confirmation_otp
  },
  verify_delivery_confirmation_otp: {
    query: "mutation verify_delivery_confirmation_otp($data: verifyDeliveryConfirmationOtpInputType!) {verify_delivery_confirmation_otp(data: $data) {hasError title message userErrors data { isCashAmountMismatch } }}",
    response: (response) => response.data.verify_delivery_confirmation_otp
  },
  get_ledger_info: {
    query: `query get_ledger_info($data: GetLedgerInfoType!) {get_ledger_info(data: $data) { hasError title message userErrors data { invoiceList { id deliveryId invoiceNo amountPaid totalAmount status createdAt updatedAt } creditNoteList { id type status receivedTransaction { id amount txDate txMode txType utr gateway paymentUrl status } paidTransaction { id amount txDate txMode txType utr gateway paymentUrl status } note createdAt updatedAt } ledgerSummary { totalPendingAmount totalReceivedCreditNoteAmount closingBalance } } }}`,
    response: (response) => response.data.get_ledger_info
  },
  create_pickup: {
    query: "mutation create_pickup($data: CreatePickupInputType!) {create_pickup(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_pickup
  },
  get_product_qc_by_stage: {
    query: "query get_product_qc_by_stage($data: GetProductQcByStageInputType!) {get_product_qc_by_stage(data: $data) {hasError title message userErrors data { qcId productId stage qcData lastUpdatedAt lastUpdatedBy {email firstName middleName lastName}  } }}",
    response: (response) => response.data.get_product_qc_by_stage
  },
  get_product_qc_summery: {
    query: "query get_product_qc_summery($data: GetProductQcSummeryInputType!) {get_product_qc_summery(data: $data) {hasError title message userErrors data { qcId stage lastUpdatedAt } }}",
    response: (response) => response.data.get_product_qc_summery
  },
  save_product_qc: {
    query: "mutation save_product_qc($data: SaveProductQcInputType!) {save_product_qc(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.save_product_qc
  },
  send_pickup_confirmation_otp: {
    query: "mutation send_pickup_confirmation_otp($data: SendPickUpConfirmationOtpInputType!) {send_pickup_confirmation_otp(data: $data) {hasError title message userErrors data { isPickUpCompleted otpExpiresAt } }}",
    response: (response) => response.data.send_pickup_confirmation_otp
  },
  verify_pickup_confirmation_otp: {
    query: "mutation verify_pickup_confirmation_otp($data: VerifyPickupConfirmationOtpInputType!) {verify_pickup_confirmation_otp(data: $data) {hasError title message userErrors data { isCashAmountMismatch } }}",
    response: (response) => response.data.verify_pickup_confirmation_otp
  },
  cancel_delivery: {
    query: "mutation cancel_delivery($data: CancelDeliveryInputType!) {cancel_delivery(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.cancel_delivery
  },
  get_transaction_list: {
    query: "query get_transaction_list($data: GetTransactionListInputType!) {get_transaction_list(data: $data) {hasError title message userErrors data { transactions { txId txDate txMode txType amount utr paymentUrl paymentUrlCreatedAt status createdAt updatedAt } remainingCount  } }}",
    response: (response) => response.data.get_transaction_list
  },
  create_credit_note: {
    query: "mutation create_credit_note($data: CreateCreditNoteInputType!) {create_credit_note(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_credit_note
  },
  get_credit_note_list:{
    query: "query get_credit_note_list($data: GetCreditNoteListInputType!) {get_credit_note_list(data: $data) {hasError title message userErrors data { creditNotes { creditNote { creditNoteId type receivedTransaction { txId txDate txMode txAmount utr status} paidTransaction { txId txDate txMode txAmount utr status } amount note creditNoteStatus createdAt updatedAt } user { id firstName middleName lastName whatsappNo } }  remainingCount  } }}",
    response: (response) => response.data.get_credit_note_list
  },
  record_invoice_payment: {
    query: "mutation record_invoice_payment($data: RecordInvoicePaymentInputType!) {record_invoice_payment(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.record_invoice_payment
  },
  cancel_invoice: {
    query: "mutation cancel_invoice($data: CancelInvoiceInputType!) {cancel_invoice(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.cancel_invoice
  },
  cancel_paid_invoice: {
    query: "mutation cancel_paid_invoice($data: CancelPaidInvoiceInputType!) {cancel_paid_invoice(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.cancel_paid_invoice
  },  
  create_communication_log: {
    query: "mutation create_communication_log($data: CreateCommunicationLogType!) {create_communication_log(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.create_communication_log
  },  
  update_communication_log: {
    query: "mutation update_communication_log($data: UpdateCommunicationLogType!) {update_communication_log(data: $data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_communication_log
  },
  get_communication_Log_List: {
    query: "query get_communication_Log_List($data: GetCommunicationLogListInputType!) {get_communication_Log_List(data: $data) {hasError title message userErrors data { dataList { id customerId adminId message createdAt updatedAt adminDetails { id firstName middleName lastName fullName emailId mobileNo } } } }}",
    response: (response) => response.data.get_communication_Log_List
  },
};

export default async function queryCloseApi({
  queryTemplate,
  queryJsonParams,
  filesJsonPayload = {}
}) {
  if (!queryTemplate || !queries[queryTemplate]) {
    throw new Error("ERROR: Invalid parameter \"queryTemplate\" provided");
  }

  const data = {};

  let isDataToBeUploaded = false;
  if (filesJsonPayload && Object.keys(filesJsonPayload).length) {
    isDataToBeUploaded = true;
    const keys = Object.keys(filesJsonPayload);
    for (let i = 0; i < keys.length; i++) {
      const payload = filesJsonPayload[keys[i]];
      if (payload instanceof File || payload instanceof FileList || (Array.isArray(payload) && payload.every(item => item instanceof File))) {
        // "payload instanceof Blob" can be used if required
        data[keys[i]] = payload;
      } else {
        throw new Error("ERROR: Invalid parameter \"filesJsonPayload\" provided");
      }
    }
  }

  data.query = queries[queryTemplate].query;

  if (queryJsonParams) {
    data.variables = { data: queryJsonParams };
  }

  let response = null;
  if (isDataToBeUploaded) {
    response = await closeApiUpload({
      // uploads files + queries
      extraHeader: {},
      data
    });
  } else {
    response = await closeApi({
      extraHeader: {},
      data
    });
  }

  if (response?.data?.errors?.length) {
    response.data.data[queryTemplate] = { ...(response.data?.data[queryTemplate] || {}), hasError: response.data.errors[0]?.hasError || false, message: response.data.errors[0]?.message || "" };
  }

  if (response?.data && response) {
    return queries[queryTemplate].response(response.data);
  }
  return response;
}
